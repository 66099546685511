import * as THREE from 'three';
import ReactGA from 'react-ga4';
import { useRef, useState, useMemo, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Text, TrackballControls } from '@react-three/drei';
import './TechCloud.css';

function Word({ children, ...props }) {
  const color = new THREE.Color();
  const fontProps = {
    font: '/Inter-Bold.woff',
    fontSize: 2.5,
    letterSpacing: -0.05,
    lineHeight: 1,
    'material-toneMapped': false
  };
  const ref = useRef();
  const [hovered, setHovered] = useState(false);
  const over = (e) => (e.stopPropagation(), setHovered(true));
  const out = () => setHovered(false);
  // Change the mouse cursor on hover
  useEffect(() => {
    if (hovered) document.body.style.cursor = 'pointer';
    return () => (document.body.style.cursor = 'auto');
  }, [hovered]);
  // Tie component to the render-loop
  useFrame(({ camera }) => {
    // Make text face the camera
    ref.current.quaternion.copy(camera.quaternion);
    // Animate font color
    ref.current.material.color.lerp(color.set(hovered ? '#000080' : 'black'), 0.1);
  });
  return <Text ref={ref} onPointerOver={over} onPointerOut={out} {...props} {...fontProps} children={children} />;
}

function Cloud({ count = 4, radius = 20 }) {
  // Create a count x count random words with spherical distribution
  let techs = [
    ['React', 'Typescript', 'Svelte', 'CSS3'],
    ['Javascript', 'Go', 'PHP', 'Kafka'],
    ['Redis', 'Node.js', 'Next.js', 'Elasticsearch'],
    ['MongoDB', 'MySQL', 'Postgres', 'Express.js']
  ];

  const words = useMemo(() => {
    const temp = [];

    const spherical = new THREE.Spherical();
    const phiSpan = Math.PI / (count + 1);
    const thetaSpan = (Math.PI * 2) / count;

    for (let i = 1; i < count + 1; i++) {
      // Taken from https://discourse.threejs.org/t/can-i-place-obects-on-a-sphere-surface-evenly/4773/6
      for (let j = 0; j < count; j++)
        temp.push([new THREE.Vector3().setFromSpherical(spherical.set(radius, phiSpan * i, thetaSpan * j)), techs[i - 1][j]]);
    }

    return temp;
  }, [count, radius]);

  return words.map(([pos, word], index) => <Word key={index} position={pos} children={word} />);
}

export default function TechCloud() {
  return (
    <div className="cloud__container">
      <div className="cloud__text">
        <p>
          it takes a lifetime to master a technology, but there's a start to everything. <br /> the sphere on the right are technologies
          that I have worked with till date
        </p>
        <p>
          I'm looking for new opportunities in a frontend/fullstack role
          {/* <a target="_blank" rel="noreferrer noopener" href="https://getmason.io/">
            Mason
          </a>{' '}
          as a Frontend Engineer */}
        </p>
        <p>
          you can find a copy of my resume{' '}
          <a
            onClick={() => ReactGA.event({ category: 'portfolio-activity', action: 'click-resume-link' })}
            target="_blank"
            rel="noreferrer noopener"
            href="https://drive.google.com/file/d/1T7KXNOKMRQRQm6L7zHAiiKf4kJSGuZJU/view?usp=sharing">
            here
          </a>
        </p>
      </div>
      <div className="cloud__obj">
        <Canvas dpr={[1, 2]} camera={{ position: [0, 7, 35], fov: 100 }}>
          <fog attach="fog" args={['#202025', 0, 80]} />
          <Cloud count={4} radius={20} />
          <TrackballControls />
        </Canvas>
      </div>
    </div>
  );
}
