import { Suspense, useRef, useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Canvas } from '@react-three/fiber';
import { useGLTF, Html, OrbitControls, Environment, ContactShadows } from '@react-three/drei';
import { FaEnvelopeOpenText, FaLinkedin } from 'react-icons/fa';
import kwesforms from 'kwesforms';
import './Contact.css';

function Model(props) {
  /*
  Auto-generated by: https://github.com/pmndrs/gltfjsx
  author: JasperTobias (https://sketchfab.com/JasperTobias)
  license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
  source: https://sketchfab.com/3d-models/lowpoly-earth-ce0cce9b528b47c7984bf0b2b600d705
  title: LowPoly Earth
  */
  const { nodes, materials } = useGLTF('/earth.gltf');
  return (
    <group rotation={[-Math.PI / 2, 0, Math.PI]} {...props} dispose={null}>
      <mesh geometry={nodes['URF-Height_Lampd_Ice_0'].geometry} material={materials.Lampd_Ice} />
      <mesh geometry={nodes['URF-Height_watr_0'].geometry} material={materials.watr} material-roughness={0} />
      <mesh geometry={nodes['URF-Height_Lampd_0'].geometry} material={materials.Lampd} material-color="lightgreen">
        <group position={[0, 0, 1.5]} rotation={[0, 0, Math.PI]}>
          <Marker rotation={[0, Math.PI / 2, Math.PI / 2]}>
            <div style={{ position: 'absolute', fontFamily: 'Fredoka', fontSize: 7, letterSpacing: -0.5, left: 17.5 }}>let's connect</div>
            <FaEnvelopeOpenText style={{ fontSize: '12px', color: 'black' }} />
          </Marker>
        </group>
      </mesh>
    </group>
  );
}

// Let's make the marker into a component so that we can abstract some shared logic
function Marker({ children, ...props }) {
  // This holds the local occluded state
  const [occluded, occlude] = useState();
  return (
    <Html
      // 3D-transform contents
      transform
      // Hide contents "behind" other meshes
      occlude
      // Tells us when contents are occluded (or not)
      onOcclude={occlude}
      // We just interpolate the visible state into css opacity and transforms
      style={{ transition: 'all 0.2s', opacity: occluded ? 0 : 1, transform: `scale(${occluded ? 0.25 : 1})` }}
      {...props}>
      {children}
    </Html>
  );
}

export default function Contact() {
  useEffect(() => {
    kwesforms.init();
  }, []);

  const ref = useRef();

  return (
    <div className="contact">
      <div className="contact__globe">
        <Canvas dpr={[1, 2]} camera={{ position: [5, 0, 0], fov: 50 }}>
          <ambientLight intensity={0.5} />
          <Suspense fallback={null}>
            <Model position={[0, 0.25, 0]} />
              <Environment path="/./" files="potsdamer_platz_1k.hdr" />
            <ContactShadows scale={5} position={[0, -1, 0]} far={0} blur={1} opacity={0.8} frames={1} />
          </Suspense>
          <OrbitControls ref={ref} />
        </Canvas>
      </div>
      <div className="contact__form">
        <div className="contact__linkedin">
          Connect on
          <a target="_blank" rel="noreferrer noopener" href="https://www.linkedin.com/in/aprvsh/">
            <FaLinkedin
              onClick={() => {
                ReactGA.event({ category: 'portfolio-activity', action: 'click-linkedin-link' });
              }}
              className="linkedin"
            />
          </a>
        </div>
        <div className="contact__divider">or drop me a message below</div>
        <form
          data-kw-has-recaptcha-v3
          data-kw-recaptcha-site-key="6LfKfCYfAAAAAKwDclMUakBVnWrEc3tXJZl2bkV8"
          className="kwes-form"
          action="https://kwesforms.com/api/foreign/forms/L5aKJolsgAaOSbGwXDtV">
          <div className="form__field">
            <input className="form__input" type="text" name="name" placeholder="name" rules="required|max:100|min:2" autoComplete="off" />
          </div>
          <div className="form__field">
            <textarea
              style={{
                height: '200px'
              }}
              className="form__input"
              type="text"
              name="message"
              placeholder="your message...."
              rules="required|max:1000"
              autoComplete="off"
            />
            <div className="form__captcha">
              This site is protected by reCAPTCHA and the <br /> Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
              <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </div>
          </div>

          <button
            onClick={() => {
              ReactGA.event({ category: 'portfolio-activity', action: 'click-kwesform-send' });
            }}
            className="form__submitbtn"
            type="submit">
            send
          </button>
        </form>
      </div>
    </div>
  );
}
