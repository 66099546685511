import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import './Mobile.css';

function Mobile() {
  useEffect(() => {
    ReactGA.initialize('G-0BM518J45M');
    ReactGA.send({ hitType: 'pageview', page: 'mobile-home' });
    ReactGA.event({ category: 'portfolio-activity', action: 'mobile-visit-home' });
  }, []);

  return (
    <main className="mobile__container">
      <h2 className="mobile__hero"> hi 👋, I'm Apoorva</h2>
      <div className="mobile__desc">
        <p>I'm a full stack developer working in the Javascript ecosystem </p>
        <p> I work with React and Node using Typescipt </p>
        <p>I'm also comfortable with Go</p>
      </div>
      <div className="mobile__links">
        <ul className="links__list">
          <li className="links__item">
            <a
              onClick={() => {
                ReactGA.event({ category: 'portfolio-activity', action: 'mobile-click-resume' });
              }}
              href="https://drive.google.com/file/d/1Kc-62IaB2yj6yFmW9c1bJN5o8xUBuTup/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer">
              resume
            </a>
          </li>
          <li className="links__item">
            <a
              onClick={() => {
                ReactGA.event({ category: 'portfolio-activity', action: 'mobile-click-projects' });
              }}
              href="https://peridot-legume-d60.notion.site/Apoorva-Projects-78812c79e5fa42f7a2f2b20218d550de"
              target="_blank"
              rel="noopener noreferrer">
              projects
            </a>
          </li>
          <li className="links__item">
            <a
              onClick={() => {
                ReactGA.event({ category: 'portfolio-activity', action: 'mobile-click-connect' });
              }}
              href="https://www.linkedin.com/in/aprvsh/"
              target="_blank"
              rel="noopener noreferrer">
              connect
            </a>
          </li>
        </ul>
      </div>
      <footer className="mobile__footer">for a richer experience, please visit this website from a desktop device</footer>
    </main>
  );
}

export default Mobile;
