import * as THREE from 'three';
import ReactGA from 'react-ga4';
import { Suspense, useRef, useState } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Preload, Image as ImageImpl } from '@react-three/drei';
import { ScrollControls, Scroll, useScroll } from './ScrollControls';
import './Projects.css';

function Image(props) {
  const ref = useRef();
  const group = useRef();
  const data = useScroll();
  useFrame((state, delta) => {
    group.current.position.z = THREE.MathUtils.damp(group.current.position.z, Math.max(0, data.delta * 50), 4, delta);
    // ref.current.material.grayscale = THREE.MathUtils.damp(ref.current.material.grayscale, Math.max(0, 1 - data.delta * 1000), 4, delta);
  });
  return (
    <group ref={group}>
      <ImageImpl ref={ref} {...props} />
    </group>
  );
}

function Page({ m = 0.4, urls, ...props }) {
  const { width } = useThree((state) => state.viewport);
  // const w = width < 10 ? 1.5 / 3 : 1 / 3 // -width*w
  return (
    <group {...props}>
      <Image position={[0, 0, -1]} scale={[5, 5, 1]} url={urls[0]} />
      <Image position={[11, 0, 0]} scale={[10, 5, 1]} url={urls[1]} />
      <Image position={[22, 0, 1]} scale={[10, 5, 1]} url={urls[2]} />
    </group>
  );
}

function Pages() {
  const { width } = useThree((state) => state.viewport);
  return (
    <>
      <Page position={[-width * 2, 0, 0]} urls={['/projects/chace/chace-1.png', '/projects/chace/1.png', '/projects/chace/2.png']} />
      <Page position={[width * 0, 0, 0]} urls={['/projects/tmm/tmm-1.png', '/projects/tmm/1.png', '/projects/tmm/3.png']} />
      <Page position={[width * 2, 0, 0]} urls={['/projects/qa/qa-1.png', '/projects/qa/1.png', '/projects/qa/2.png']} />
      <Page position={[width * 4, 0, 0]} urls={['/projects/sb/sb-1.png', '/projects/sb/1.png', '/projects/sb/2.png']} />
      <Page position={[width * 6, 0, 0]} urls={['/projects/chace/chace-1.png', '/projects/chace/1.png', '/projects/chace/2.png']} />
    </>
  );
}

export default function Projects() {
  const [links, setLinks] = useState(false);

  const Urls = () => (
    <div
      style={{
        fontFamily: 'Fredoka'
      }}>
      <ul className="links">
        <li>
          <a href="https://themusicmod.vercel.app" target="_blank" rel="noreferrer noopener">
            TheMusicMod
          </a>
        </li>
        <li>
          <a href="https://quizalpha.vercel.app" target="_blank" rel="noreferrer noopener">
            QuizAlpha
          </a>
        </li>
        <li>
          <a href="https://secureblink.com" target="_blank" rel="noreferrer noopener">
            Secure Blink
          </a>
        </li>
        <li>
          <a href="https://chace.co.in" target="_blank" rel="noreferrer noopener">
            Chace
          </a>
        </li>
      </ul>
    </div>
  );

  return (
    <Canvas gl={{ antialias: false }} dpr={[1, 1.5]}>
      <Suspense fallback={null}>
        <ScrollControls infinite horizontal damping={4} pages={9} distance={1}>
          <Scroll>
            <Pages />
          </Scroll>
          <Scroll html>
            <p
              style={{
                position: 'absolute',
                top: '10vh',
                left: '30.5vw',
                fontSize: '18px',
                fontFamily: 'Fredoka'
              }}>
              selection of projects that I have worked on {'('}
              <a
                style={{
                  color: 'inherit',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setLinks(!links);
                  !links && ReactGA.event({ category: 'portfolio-activity', action: 'click-project-links' });
                }}>
                links
              </a>
              {')'}
              {links && <Urls />}
            </p>
          </Scroll>
        </ScrollControls>
        <Preload />
      </Suspense>
    </Canvas>
  );
}
